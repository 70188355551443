import { Injectable, NgZone, Optional } from '@angular/core';
import { WSService } from '@kuki/global/shared/services/ws.service';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { LogEvents, PubSubMessageTypes } from '@kuki/global/shared/types/enum';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { Observable, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { VolumeService } from '@kuki/global/features/volume-v2/volume.service';
import { hal } from '@kuki/platforms/hal';
import { Log, LogOptions } from '@kuki/global/shared/types/general';

@Injectable()
export class LoggingService {

    private readonly LOGGING_INTERVAL = 30 * 1000;
//    private readonly LOGGING_INTERVAL = 120 * 1000;
    private readonly DEFAULT_OPTIONS: LogOptions = {
        first: true,
        repeatable: true,
        requirePlayback: false,
        sendAudio: hal.cap.volumeControl
    };
    private logState: {
        event: LogEvents,
        options: LogOptions
    };
    private subscription: SubscriptionObject = {};

    private onRefreshAutoLogging: Subject<void> = new Subject<void>();

    private onAutoLog: Subject<void> = new Subject<void>();
    public onAutoLog$: Observable<void> = this.onAutoLog.asObservable();

    constructor(
        private wsService: WSService,
        private ngZone: NgZone,
        private componentRegisterService: ComponentRegisterService,
        private authService: AuthService,
        private profileService: ProfileService,
        @Optional() private volumeService: VolumeService) {
    }

    public init() {
        SOM.clearSubscriptions(this.subscription.loggingInterval);
        this.ngZone.runOutsideAngular(() => {
            this.subscription.loggingInterval = this.onRefreshAutoLogging
                .pipe(
                    switchMap(() => timer(this.LOGGING_INTERVAL, this.LOGGING_INTERVAL))
                ).subscribe(() => {
                    this.repeat();
                });
        });
    }

    public log(event: LogEvents = this.logState.event, options?: LogOptions): boolean {
        options = { ...this.DEFAULT_OPTIONS, ...options };
        if (options?.repeatable) {
            this.logState = {
                event: event,
                options: options
            };
        }
        const logObject = this.generateLogObject(event, options);
        if (!logObject) {
            return false;
        }
        // if (hal.platform === 'TV.ARRIS') {
        //     console.log(`LOGEVENT ${ new Date() } ${ Date.now() }`);
        //     console.log(JSON.stringify(logObject));
        // }
        this.wsService.pub(logObject);
        if (options?.first) {
            this.onRefreshAutoLogging.next();
        }
        return true;
    }

    public repeat() {
        if (!this.logState) {
            return;
        }
        this.log(this.logState.event, {
            ...this.logState.options,
            first: false
        });
    }

    public getLogState() {
        return this.logState;
    }

    private generateLogObject(event: LogEvents, options?: LogOptions): Log {
        const authData = this.authService.getAuthData();
        if (!authData) {
            return;
        }
        const logObject: Log = {
            type: PubSubMessageTypes.LOG,
            event: event,
            first: options?.first,
            client: {
                device_id: authData.device_id,
                contract_id: authData.contract_id,
                subject_id: authData.subject_id,
                profile_id: this.profileService.getActiveProfile()?.id
            }
        };

        const mediaPlayer = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (options?.requirePlayback) {
            if (!mediaPlayer) {
                return;
            }
            logObject.playback = mediaPlayer.mediaPlayerV2Service.getLogPlaybackObject();
            if (!logObject.playback) {
                return;
            }
        } else if (mediaPlayer) {
            logObject.playback = mediaPlayer.mediaPlayerV2Service.getLogPlaybackObject();
        }

        if (options?.sendAudio) {
            logObject.audio = this.volumeService.getAudioState();
        }
        return logObject;
    }

    public destroy() {
        this.logState = undefined;
        SOM.clearSubscriptionsObject(this.subscription);
    }

    // Log playback object
    // {
    //     "type": "LOG", // konstanta !
    //     "playback":
    //         {
    //             "type": "live", // timeshift, serial, vod, npvr
    //             "time": 1233131313, // time play unixtimestamp
    //             "entity":{
    //                 "epg":{
    //                     "channel":{
    //                         "id":1, // channel id if exist (timeshift, live)
    //                         "name": "CT1"
    //                     },
    //                     "id":3,
    //                     "title": "Ordinace v ružové zhradě 1234",
    //                     "datetime_from": 1232112, // Time stamp start
    //                     "datetime_to": 1232999, // Time stamp to
    //                 },
    //                 "serial": {
    //                     "title":"Zde",
    //                     "id": 3
    //                 },
    //                 "vod": {
    //                     "id":34,
    //                     "title": "Title"
    //                 },
    //                 "npvr": {
    //                     "id":31
    //                 }
    //             }
    //         },
    //     "event": "PLAY", // PAUSE, END
    //     "first": true, // bool
    //     "client": {
    //         "device_id":1234,
    //         "contract_id":1234,
    //         "profile_id": 1234// if profile activated
    //     }
    // }
}
